export const filtersList = [
  {
    id: 1,
    title: 'Month',
    name: 'Filter',
    data: [],
  },
  {
    id: 2,
    title: 'Active Since',
    name: 'Filter',
    data: [],
  },
  {
    id: 3,
    title: 'Owner',
    name: 'Filter',
    data: [],
  },
];

export const crudOperations = [
  { operationName: 'Inserts', value: 'Insert', selected: true }, { operationName: 'Reads', value: 'Read', selected: true },
  { operationName: 'Updates', value: 'Update', selected: true }, { operationName: 'Deletes', value: 'Delete', selected: true },
];

export const otherOperations = [
  { operationName: 'Visualforce Request', value: 'visualforcerequest', selected: true }, { operationName: 'Salesforce UI Interaction (URI)', value: 'salesforceuiinteraction', selected: true },
  { operationName: 'Apex Scheduled Job', value: 'apexscheduledjob', selected: true }, { operationName: 'Apex Batch Job', value: 'apexbatchjob', selected: true },
  { operationName: 'Apex Rest Web Service', value: 'apexrestwebservice', selected: true }, { operationName: 'Apex SOAP Web Service', value: 'apexsoapwebservice', selected: true },
  { operationName: 'API(Rest)', value: 'apirest', selected: true }, { operationName: 'API', value: 'api', selected: true }, { operationName: 'Visualforce Remotting', value: 'visualforceremotting', selected: true },
  { operationName: 'Platform Event', value: 'platformevent', selected: true }, { operationName: 'Unassociated CRUD', value: 'unassociationcrud', selected: true },
];

export const entityName = {
  NESTED_FEATURE: ['NestedFeature'],
  CUSTOM_OBJECT: ['CustomObject'],
  LIGHTNING_PAGE: ['LightingPage'],
  LIGHTNING_COMPONENT: ['LightningComponent'],
  VISUALFORCE_PAGE: ['VisualforcePage'],
  APEX_EXECUTION: ['ApexClass', 'ApexTrigger'],
  APEX_CLASS: ['ApexClass'],
  APEX_TRIGGER: ['ApexTrigger'],

};

export const operationTypeList = [
  {
    id: 'nestedFeatures', name: 'Nested Features', type: entityName.NESTED_FEATURE,
  },
  {
    id: 'customObject', name: 'Custom Objects', type: entityName.CUSTOM_OBJECT,
  },
  {
    id: 'lightingPages', name: 'Lighting Pages', type: entityName.LIGHTNING_PAGE,
  },
  {
    id: 'lightningComponents', name: 'Lighting Components', type: entityName.LIGHTNING_COMPONENT,
  },
  {
    id: 'visualForcePages', name: 'Visualforce Pages', type: entityName.VISUALFORCE_PAGE,
  },
  {
    id: 'apexExecutions', name: 'Apex Executions', type: entityName.APEX_EXECUTION,
  },
];
