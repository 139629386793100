import { Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';
import { usePboConnection } from './usePboConnection';
import { ConfirmDialogBox } from '../../components/CustomDialogBox';

function PboConnection() {
  const classes = useStyles(styles)();
  const {
    handleSave,
    handleDelete,
    handleRefresh,
    connected,
    pboConnectionDetails,
    redirecting,
    openActionDialog,
    actionDialogData,
    setOpenActionDialog,
  } = usePboConnection();
  const isEnableChange = pboConnectionDetails?.data && !pboConnectionDetails?.data?.isEnable;

  return (
    <Grid container gap={2} sx={{ height: '100%' }}>
      <Grid container item xs={12} className={classes.cardStyles} bgcolor="#EBF1F7 !important" justifyContent="space-between" alignItems="center">
        Refreshes the connection and initiates a new session.
        <LoadingButton
          variant="outlined"
          size="medium"
          onClick={handleRefresh}
          disabled={(isEnableChange) || !connected}
          loading={(pboConnectionDetails?.loading && pboConnectionDetails?.type === 'refresh') || false}
        >
          Refresh
        </LoadingButton>
      </Grid>

      <Grid container item xs={12} className={classes.cardStyles} direction="column" sx={{ height: '100%' }} gap={6} justifyContent="center" alignItems="center">
        <Grid item container justifyContent="center">
          <Typography fontSize="24px" maxWidth="650px" width="80%" textAlign="center">
            Establishes connectivity between the ISV Insights and LMA org to retrieve App Analytics data.
          </Typography>
        </Grid>
        <Grid item>
          <Stack direction="row" gap={2}>
            <LoadingButton
              loading={redirecting || false}
              variant="contained"
              size="medium"
              onClick={() => handleSave(false)}
              disabled={!!connected}
            >
              {connected ? 'Connected' : 'Connect PBO'}
            </LoadingButton>

            <LoadingButton
              loading={(pboConnectionDetails?.loading && pboConnectionDetails?.type === 'disconnect') || false}
              variant="outlined"
              size="medium"
              onClick={handleDelete}
              disabled={(isEnableChange) || !connected}
              sx={{
                borderColor: 'error.lighter',
                color: 'error.main',
                '&:hover': {
                  backgroundColor: 'error.lighter',
                  borderColor: 'error.lighter',
                  color: 'error.dark',
                },
              }}
            >
              Disconnect PBO
            </LoadingButton>
            <ConfirmDialogBox
              title={actionDialogData.title}
              message={actionDialogData.message}
              openDialog={openActionDialog}
              onClose={setOpenActionDialog}
              onConfirm={actionDialogData.onConfirm}
            />
          </Stack>
        </Grid>
        <Grid item>
          <img src="/connectPbo.png" alt="alt" style={{ maxWidth: '100%' }} />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default PboConnection;
