import { ConfirmDialogBox } from '../../components/CustomDialogBox';
import { withLoadingAndOverlay } from '../../hoc';
import { FeatureManagerTable } from '../../modules/FeatureManager';

function FeatureManager(props) {
  const {
    permissions,
    data,
    columns,
    handleClickEdit,
    openDialog,
    handleClose,
    handleDeleteConfirm,
  } = props;

  return (
    <>
      <FeatureManagerTable
        permissions={permissions}
        rows={data}
        columns={columns}
        handleClickEdit={handleClickEdit}
      />
      <ConfirmDialogBox
        title="Delete Feature"
        message="Are you sure you want to delete this Feature?"
        openDialog={openDialog}
        onClose={handleClose}
        onConfirm={handleDeleteConfirm}
      />
    </>
  );
}
export default withLoadingAndOverlay(FeatureManager);
