import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import useStyles from '../hooks/useStyles';
import styles from '../layouts/pages/style';
import GeographyCountryCard from '../sections/@dashboard/geography/GeographyCountryCard';
import { PackageHeader } from '../components/CustomHeading';
import { getAccCountByCountryLang, getCountryKpiScore } from '../features';
import usePackageSelection from '../modules/Accounts/components/PackageSelection/usePackageSelection';
import Metatags from '../components/MetaTags/MetaTags';
import GeoMap from '../modules/Geography/GeoMap';
import GeoKpiScore from '../modules/Geography/GeoKpiScore';

export default function GeographiesPage() {
  const dispatch = useDispatch();
  const [refreshGeography, setRefreshGeography] = useState(false);
  const [refreshGeoKpiScore, setRefreshGeoKpiScore] = useState(false);
  const {
    accCountByCountry, loading, error,
  } = useSelector((state) => state.accCountByCountryLang);
  const {
    versionData, loading: versionDataLoading, error: versionDataError,
  } = useSelector((state) => state.countryKpiScore);
  const { activePackage } = usePackageSelection();
  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getAccCountByCountryLang({ packageId: activePackage.packageId }));
    }
  }, [refreshGeography, activePackage?.packageId]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getCountryKpiScore({ pkgId: activePackage.packageId }));
    }
  }, [refreshGeoKpiScore, activePackage?.packageId]);

  const classes = useStyles(styles)();
  const [country, setCountry] = useState('');

  return (
    <>
      <Metatags metaTagName="Geographies | ISV Insights" />
      <Container maxWidth="xl">
        <Grid container gap={2}>
          <Grid item xs={12} className={classes.cardStyles}>
            <PackageHeader heading="Geography" />
          </Grid>

          <Grid container item xs={12} justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <GeographyCountryCard
                headerIcon={<LanguageIcon />}
                title="Countries"
                cardHeadTitle="COUNTRY"
                whichList="Countries"
                refresh={setRefreshGeography}
                loading={loading}
                error={error}
                data={accCountByCountry}
                setCountry={setCountry}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <GeoMap country={country} setCountry={setCountry} data={accCountByCountry} />
            </Grid>
          </Grid>
          <Grid item xs={12} justifyContent="space-between" spacing={2} className={classes.cardStyles}>
            <GeoKpiScore
              refresh={setRefreshGeoKpiScore}
              loading={versionDataLoading}
              error={versionDataError}
              data={versionData}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
