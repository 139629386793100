import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import palette from '../../../theme/palette';
import { formikForm } from '../../../hoc';
import OTPInputs from '../../../components/FormComponents/OTPInput';
import TextFieldWithError from '../../../components/FormComponents/TextfieldWithError';

function SetPasswordForm(props) {
  const {
    handleTogglePassword, auth, showPassword, storedEmail, isReset, formik,
  } = props;

  const {
    errors, handleChange, touched, setFieldValue, getIn,
  } = formik;

  return (
    <Grid container>
      <Typography variant="h4">Set New Password</Typography>
      <TextFieldWithError
        name="email"
        onChange={handleChange}
        label="Email Address"
        error={touched.email && getIn(errors, 'email')}
        InputProps={{
          readOnly: true,
          style: { backgroundColor: '#f5f5f5', pointerEvents: 'none' },
        }}
        value={storedEmail}
      />
      {!isReset && (
      <TextFieldWithError
        name="fullName"
        onChange={handleChange}
        label="Name"
        error={touched.fullName && getIn(errors, 'fullName')}
      />
      )}
      <TextFieldWithError
        error={touched.newPassword && getIn(errors, 'newPassword')}
        name="newPassword"
        label="New Password"
        type={showPassword ? 'text' : 'password'}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{ color: 'grey' }}
                onClick={handleTogglePassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextFieldWithError
        error={touched.confirmPassword && getIn(errors, 'confirmPassword')}
        name="confirmPassword"
        label="Confirm Password"
        type={showPassword ? 'text' : 'password'}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{ color: 'grey' }}
                onClick={handleTogglePassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {isReset && (
      <Grid item xs={12}>
        <Typography my={1} ml={0.5}>Verification Code</Typography>
        <OTPInputs setFieldValue={setFieldValue} length={6} label="Validation Code" />
      </Grid>
      )}
      <Grid item xs={12}>
        <LoadingButton
          color="primary"
          loading={auth?.loading || false}
          loadingPosition="start"
          variant="contained"
          fullWidth
          sx={{ my: '20px' }}
          type="submit"
        >
          {isReset ? 'Reset' : 'Set'}
          {' '}
          Password
        </LoadingButton>
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        Don&apos;t have an account ?
        {' '}
        <a href="https://isvinsights.ai/contact-us" variant="body2" style={{ textDecoration: 'none', color: palette.primary.mainColor }} target="_blank" rel="noreferrer">Contact Us</a>
      </Grid>
    </Grid>
  );
}

export default formikForm(SetPasswordForm);
