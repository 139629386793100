import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Typography, Stack, Tooltip,
} from '@mui/material';
import { CustomDialogBox } from '../../../../components/CustomDialogBox';
import palette from '../../../../theme/palette';
import useStyles from '../../../../hooks/useStyles';
import { crudOperations } from '../../constants';
import styles from '../../style';
import { DynamicTable } from '../../../../components/CustomTable';

function tableContentHeader({ customTypes, feature, classes }) {
  return (
    <Box>
      <Stack
        justifyContent="start"
        direction="row"
        padding="2px 8px"
        alignItems="center"
        color="black"
        border="1px solid #3366FF"
        borderRadius="5px"
        width="max-content"
        maxWidth="380px"
      >
        {React.cloneElement(customTypes[[feature.custom_entity_type || feature.type]], { fontSize: 'inherit', style: { color: palette.secondary.main } })}
        <Stack marginLeft={1}>
          <Tooltip title={feature.custom_entity_label || feature.nested_feature_name}>
            <Typography
              style={{
                fontSize: 15,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordBreak: 'break-all',
              }}
            >
              {feature.custom_entity_label || feature.nested_feature_name}
            </Typography>
          </Tooltip>
        </Stack>
      </Stack>
      <Box className={classes.eventType}>
        {feature.operation_type?.length === crudOperations.length ? (
          <Typography display="inline" variant="caption">All Events</Typography>
        ) : (
          feature.operation_type?.map((op, index) => (
            <React.Fragment key={op}>
              {index !== 0 && <span className={classes.pipe}>|</span>}
              <Typography display="inline" variant="caption">{op}</Typography>
            </React.Fragment>
          ))
        )}
      </Box>
    </Box>
  );
}

function DialogContentElement({ data, customTypes }) {
  const classes = useStyles(styles)();
  const columns = [
    { field: 'Component' },
    { field: 'Type', align: 'right' },
  ];
  const modifiedRows = data.map((feature) => ({
    Component: tableContentHeader({ customTypes, feature, classes }),
    Type: <Box style={{ fontWeight: 'bold', textAlign: 'right' }}>{feature.custom_entity_type || feature.type}</Box>,
  }));
  return (
    <DynamicTable dialog showHeader rows={modifiedRows} columns={columns} />
  );
}

export default function FeatureLabelModal(props) {
  const {
    open,
    handleClose,
    label,
    featuresList,
    handleClickEdit,
    customTypes,
    permissions,
  } = props;

  const [selectedCustomEntity, setSelectedCustomEntity] = useState([]);
  const [selectedNestedFeatures, setSelectedNestedFeatures] = useState([]);

  const featureData = useSelector((state) => state.featureDetailList);
  const customEntityData = useSelector((state) => state.customEntityList);
  const featureList = useSelector((state) => state.featureManagerList?.featureManagerList);

  const getNestedFeatureInfo = (data) => {
    const nestedFeatureInfo = [];
    if (featuresList) {
      data?.forEach((feature) => {
        const nestedData = featuresList.find((obj) => obj.featureId === feature.nested_feature_id);
        if (nestedData) {
          const cloneNestedData = {
            nested_feature_id: nestedData.featureId,
            nested_feature_name: nestedData.featureName,
            type: 'NestedFeature',
          };
          nestedFeatureInfo.push(cloneNestedData);
        }
      });
    } else {
      data?.forEach((feature) => {
        const nestedData = featureList.find((obj) => obj.feature_id === feature.nested_feature_id);
        if (nestedData) {
          const cloneNestedData = {
            nested_feature_id: nestedData.feature_id,
            nested_feature_name: nestedData.feature_name,
            type: 'NestedFeature',
          };
          nestedFeatureInfo.push(cloneNestedData);
        }
      });
    }

    return nestedFeatureInfo;
  };

  useEffect(() => {
    if (featureData?.customEntity?.length && customEntityData?.customEntityList?.length) {
      const filterData = [];
      featureData.customEntity.forEach((feature) => {
        const customData = customEntityData.customEntityList.find((obj) => obj.custom_entity_id === feature.custom_entity_id);
        if (customData) {
          const cloneCustomData = { ...customData };
          cloneCustomData.operation_type = JSON.parse(feature.operation_type);
          filterData.push(cloneCustomData);
        }
        setSelectedCustomEntity(filterData);
      });
    } else setSelectedCustomEntity([]);

    if (featureData?.nestedFeatures?.length) {
      const nestedFeatureInfo = getNestedFeatureInfo(featureData.nestedFeatures);
      setSelectedNestedFeatures(nestedFeatureInfo);
    } else setSelectedNestedFeatures([]);
  }, [customEntityData, featureData]);

  const mergedData = [...selectedCustomEntity, ...selectedNestedFeatures];
  return (
    <CustomDialogBox
      table
      title={label}
      open={open}
      handleClose={handleClose}
      content={(
        <DialogContentElement data={mergedData} customTypes={customTypes} />
    )}
      actions={permissions.write && { title: 'Edit Feature', onClick: handleClickEdit }}
    />
  );
}
