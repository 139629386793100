import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TerminalOutlined, ViewComfyOutlined, AspectRatioOutlined, ElectricBoltOutlined, FitScreenOutlined, AirlineStops,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { crudOperations, operationTypeList, entityName } from '../../constants';
import { addFeature, updateFeature, getKpiPreview } from '../../../../features';
import usePackageSelection from '../../../Accounts/components/PackageSelection/usePackageSelection';

const useAddEditFeature = () => {
  const location = useLocation();
  const editFeatureData = location.state ? location.state.editFeatureData : {};
  const redirectFromLogAnalyzer = location.state ? location.state.redirectFromLogAnalyzer : false;
  const isCopyClick = location.state ? location.state.isCopyClick : 'false';
  const [editData, setEditData] = useState(!redirectFromLogAnalyzer ? editFeatureData : {});
  const [openPick, setOpenPick] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [openRefineFilter, setOpenRefineFilter] = useState(false);
  const [type, setType] = useState('');
  const [name, setName] = useState(editFeatureData?.name ? editFeatureData.name : '');
  const [label, setLabel] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [previewDisabled, setPreviewDisabled] = useState(true);
  const [customFilter, setCustomFilter] = useState('');
  const [nestedFeatures, setNestedFeatures] = useState({
    ids: editFeatureData?.[entityName?.NESTED_FEATURE]?.ids ? editFeatureData[entityName.NESTED_FEATURE].ids : [],
    data: editFeatureData?.[entityName?.NESTED_FEATURE]?.data ? editFeatureData[entityName.NESTED_FEATURE].data : [],
  });
  const [customObject, setCustomObject] = useState({
    ids: editFeatureData?.[entityName?.CUSTOM_OBJECT]?.ids ? editFeatureData[entityName.CUSTOM_OBJECT].ids : [],
    data: editFeatureData?.[entityName?.CUSTOM_OBJECT]?.data ? editFeatureData[entityName.CUSTOM_OBJECT].data : [],
  });
  const [lightingPages, setLightingPages] = useState({
    ids: editFeatureData?.[entityName?.LIGHTNING_PAGE]?.ids ? editFeatureData[entityName.LIGHTNING_PAGE].ids : [],
    data: editFeatureData?.[entityName?.LIGHTNING_PAGE]?.data ? editFeatureData[entityName.LIGHTNING_PAGE].data : [],
  });
  const [lightningComponents, setLightningComponents] = useState({
    ids: editFeatureData?.[entityName?.LIGHTNING_COMPONENT]?.ids ? editFeatureData[entityName.LIGHTNING_COMPONENT].ids : [],
    data: editFeatureData?.[entityName?.LIGHTNING_COMPONENT]?.data ? editFeatureData[entityName.LIGHTNING_COMPONENT].data : [],
  });
  const [visualForcePages, setVisualForcePages] = useState({
    ids: editFeatureData?.[entityName?.VISUALFORCE_PAGE]?.ids ? editFeatureData[entityName.VISUALFORCE_PAGE].ids : [],
    data: editFeatureData?.[entityName?.VISUALFORCE_PAGE]?.data ? editFeatureData[entityName.VISUALFORCE_PAGE].data : [],
  });
  const [apexExecutions, setApexExecutions] = useState(() => {
    const obj = {
      ids: [],
      data: [],
    };

    entityName?.APEX_EXECUTION.forEach((ele) => {
      if (editFeatureData?.[ele] && editFeatureData[ele].ids?.length && editFeatureData[ele].data?.length) {
        obj.ids.push(...editFeatureData?.[ele].ids ?? []);
        obj.data.push(...editFeatureData?.[ele].data ?? []);
      }
    });

    return obj;
  });

  const customEntityData = useSelector((state) => state.customEntityList);
  const featuresList = useSelector((state) => state.featuresList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activePackage } = usePackageSelection();

  // Functions
  const handlePickOpen = (operationType) => {
    setOpenPick(true);
    setType(operationType);
    switch (operationType) {
      case entityName?.NESTED_FEATURE:
        setLabel('Nested Feature');
        break;
      case entityName?.CUSTOM_OBJECT:
        setLabel('Custom Object');
        break;
      case entityName?.LIGHTNING_PAGE:
        setLabel('Lighting Pages');
        break;
      case entityName?.LIGHTNING_COMPONENT:
        setLabel('Lighting Components');
        break;
      case entityName?.VISUALFORCE_PAGE:
        setLabel('VisualForce Pages');
        break;
      case entityName?.APEX_EXECUTION:
        setLabel('Apex Executions');
        break;
      default:
    }
  };

  const handlePickClose = () => {
    setOpenPick(false);
    setType('');
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setPreviewData({});
  };

  const handleDisabled = (types, data) => {
    let array = [];
    const localType = Array.isArray(types) ? types[0] : types;

    switch (localType) {
      case entityName?.NESTED_FEATURE[0]:
        array = [...data, ...customObject.ids, ...lightingPages.ids, ...lightningComponents.ids, ...visualForcePages.ids, ...apexExecutions.ids];
        break;
      case entityName?.CUSTOM_OBJECT[0]:
        array = [...nestedFeatures.ids, ...data, ...lightingPages.ids, ...lightningComponents.ids, ...visualForcePages.ids, ...apexExecutions.ids];
        break;
      case entityName?.LIGHTNING_PAGE[0]:
        array = [...nestedFeatures.ids, ...data, ...customObject.ids, ...lightningComponents.ids, ...visualForcePages.ids, ...apexExecutions.ids];
        break;
      case entityName?.LIGHTNING_COMPONENT[0]:
        array = [...nestedFeatures.ids, ...data, ...lightingPages.ids, ...customObject.ids, ...visualForcePages.ids, ...apexExecutions.ids];
        break;
      case entityName?.VISUALFORCE_PAGE[0]:
        array = [...nestedFeatures.ids, ...data, ...lightingPages.ids, ...lightningComponents.ids, ...customObject.ids, ...apexExecutions.ids];
        break;
      case (entityName?.APEX_EXECUTION[0] || entityName?.APEX_EXECUTION[1]):
        array = [...nestedFeatures.ids, ...data, ...lightingPages.ids, ...lightningComponents.ids, ...visualForcePages.ids, ...customObject.ids];
        break;
      default:
        array = [...nestedFeatures.ids, ...customObject.ids, ...lightingPages.ids, ...lightningComponents.ids, ...visualForcePages.ids, ...apexExecutions.ids];
        break;
    }

    const nameData = types === 'name' ? data : name;
    if (types !== 'preview') setDisabled(!(array.length && nameData));
    setPreviewDisabled(!array.length);
  };

  const handleChange = (e) => {
    setName(e.target.value);
    handleDisabled('name', e.target.value);
  };

  const handleEditData = (types, data) => {
    const dataObj = {};
    dataObj.id = editData.id;
    dataObj.name = editData.name;
    dataObj[entityName?.NESTED_FEATURE] = nestedFeatures;
    dataObj[entityName?.CUSTOM_OBJECT] = customObject;
    dataObj[entityName?.LIGHTNING_PAGE] = lightingPages;
    dataObj[entityName?.LIGHTNING_COMPONENT] = lightningComponents;
    dataObj[entityName?.VISUALFORCE_PAGE] = visualForcePages;
    dataObj[entityName?.APEX_EXECUTION] = apexExecutions;
    switch (types) {
      case entityName?.NESTED_FEATURE:
        dataObj[entityName?.NESTED_FEATURE] = data;
        break;
      case entityName?.CUSTOM_OBJECT:
        dataObj[entityName?.CUSTOM_OBJECT] = data;
        break;
      case entityName?.LIGHTNING_PAGE:
        dataObj[entityName?.LIGHTNING_PAGE] = data;
        break;
      case entityName?.LIGHTNING_COMPONENT:
        dataObj[entityName?.LIGHTNING_COMPONENT] = data;
        break;
      case entityName?.VISUALFORCE_PAGE:
        dataObj[entityName?.VISUALFORCE_PAGE] = data;
        break;
      case entityName?.APEX_EXECUTION:
        dataObj[entityName?.APEX_CLASS] = data;
        break;
      default:
    }
    setEditData(dataObj);
  };

  const handleSelect = (ids, types) => {
    const sameCustomObjectEntity = customObject.data.filter((obj) => ids.includes(obj.custom_entity_id));
    const filterData = [];
    if (customEntityData.customEntityList.length) {
      customEntityData.customEntityList.forEach((obj) => {
        if (ids.includes(obj.custom_entity_id)) {
          const returnObj = {};
          returnObj.custom_entity_label = obj.custom_entity_label;
          returnObj.custom_entity_id = obj.custom_entity_id;
          if (types[0] === entityName?.CUSTOM_OBJECT[0]) {
            const matchingObject = sameCustomObjectEntity.find((matchingObj) => matchingObj.custom_entity_id === obj.custom_entity_id);
            const crudOp = crudOperations.map((opData) => opData.value);
            if (matchingObject) {
              returnObj.operation_type = matchingObject.operation_type;
              returnObj.allEvent = crudOperations.every((data) => {
                return matchingObject?.operation_type?.includes(data.value);
              });
            } else {
              // TODO do later
              // const otherOp = otherOperations.map((opData) => opData.value);
              returnObj.operation_type = [...crudOp];
              returnObj.allEvent = true;
              // returnObj.allSource = true;
            }
          }
          filterData.push(returnObj);
        }
      });
    }
    if (featuresList?.response?.responseArray?.length) {
      const features = featuresList?.response?.responseArray;
      features.forEach((obj) => {
        if (ids.includes(obj.feature_id)) {
          const returnObj = {};
          returnObj.nested_feature_name = obj.feature_name;
          returnObj.nested_feature_id = obj.feature_id;
          filterData.push(returnObj);
        }
      });
    }
    const dataSet = { ids, data: filterData };
    switch (types[0]) {
      case entityName?.NESTED_FEATURE[0]:
        setNestedFeatures(dataSet);
        break;
      case entityName?.CUSTOM_OBJECT[0]:
        setCustomObject(dataSet);
        break;
      case entityName?.LIGHTNING_PAGE[0]:
        setLightingPages(dataSet);
        break;
      case entityName?.LIGHTNING_COMPONENT[0]:
        setLightningComponents(dataSet);
        break;
      case entityName?.VISUALFORCE_PAGE[0]:
        setVisualForcePages(dataSet);
        break;
      case (entityName?.APEX_EXECUTION[0] || entityName?.APEX_EXECUTION[1]):
        setApexExecutions(dataSet);
        break;
      default:
    }
    handleEditData(types, dataSet);
    handlePickClose();
    handleDisabled(types, ids);
  };

  const handleRemove = (id, types) => {
    let updateIds = [];
    let dataObj = { ids: [], data: [] };
    switch (types) {
      case entityName?.NESTED_FEATURE:
        {
          updateIds = nestedFeatures.ids.filter((obj) => obj !== id);
          const updateData = nestedFeatures.data.filter((obj) => obj.nested_feature_id !== id);
          dataObj = { ids: updateIds, data: updateData };
          setNestedFeatures(dataObj);
        }
        break;
      case entityName?.CUSTOM_OBJECT:
        {
          updateIds = customObject.ids.filter((obj) => obj !== id);
          const updateData = customObject.data.filter((obj) => obj.custom_entity_id !== id);
          dataObj = { ids: updateIds, data: updateData };
          setCustomObject(dataObj);
        }
        break;
      case entityName?.LIGHTNING_PAGE:
        {
          updateIds = lightingPages.ids.filter((obj) => obj !== id);
          const updateData = lightingPages.data.filter((obj) => obj.custom_entity_id !== id);
          dataObj = { ids: updateIds, data: updateData };
          setLightingPages(dataObj);
        }
        break;
      case entityName?.LIGHTNING_COMPONENT:
        {
          updateIds = lightningComponents.ids.filter((obj) => obj !== id);
          const updateData = lightningComponents.data.filter((obj) => obj.custom_entity_id !== id);
          dataObj = { ids: updateIds, data: updateData };
          setLightningComponents(dataObj);
        }
        break;
      case entityName?.VISUALFORCE_PAGE:
        {
          updateIds = visualForcePages.ids.filter((obj) => obj !== id);
          const updateData = visualForcePages.data.filter((obj) => obj.custom_entity_id !== id);
          dataObj = { ids: updateIds, data: updateData };
          setVisualForcePages(dataObj);
        }
        break;
      case entityName?.APEX_EXECUTION:
        {
          updateIds = apexExecutions.ids.filter((obj) => obj !== id);
          const updateData = apexExecutions.data.filter((obj) => obj.custom_entity_id !== id);
          dataObj = { ids: updateIds, data: updateData };
          setApexExecutions(dataObj);
        }
        break;
      default:
    }
    handleEditData(types, dataObj);
    handleDisabled(types, updateIds);
  };

  const handlePreviewBtn = () => {
    const saveData = {};
    saveData.featureName = name;
    saveData.customEntityObjects = {
      CustomObject: (customObject.data || []),
      ApexClass: (apexExecutions.data || []).map((obj) => obj.custom_entity_id),
      LightningComponent: (lightningComponents.data || []).map((obj) => obj.custom_entity_id),
      LightningPage: (lightingPages.data || []).map((obj) => obj.custom_entity_id),
      NestedFeatures: (nestedFeatures.ids || []),
      VisualforcePage: (visualForcePages.data || []).map((obj) => obj.custom_entity_id),

    };
    saveData.packageId = activePackage.packageId;
    dispatch(getKpiPreview({ data: saveData, packageId: activePackage.packageId })).then((res) => {
      if (res.error) {
        dispatch(openSnackbar({ open: true, message: 'Whoops! Something went wrong.', type: 'error' }));
      } else {
        setOpenPreview(true);
        setPreviewData(res?.payload ?? {});
      }
    });
  };

  const handleSaveBtn = () => {
    const saveData = {};
    saveData.featureName = name;
    saveData.customEntity = [
      ...customObject.data,
      ...lightingPages.data,
      ...lightningComponents.data,
      ...visualForcePages.data,
      ...apexExecutions.data,
    ];
    saveData.nestedFeatures = nestedFeatures.data;
    saveData.featureId = editFeatureData.id;
    saveData.packageId = activePackage.packageId;
    if (!saveData.featureId || isCopyClick) {
      dispatch(addFeature({ data: saveData, packageId: activePackage.packageId })).then((res) => {
        const { payload } = res;
        if (res.error) {
          dispatch(openSnackbar({ open: true, message: payload, type: 'error' }));
        } else {
          dispatch(openSnackbar({ open: true, message: 'Feature Created Successfully', type: 'success' }));
          navigate('/feature-manager');
        }
      });
    } else {
      dispatch(updateFeature(saveData)).then((res) => {
        const { payload } = res;
        if (res.error) {
          dispatch(openSnackbar({ open: true, message: payload, type: 'error' }));
        } else {
          dispatch(openSnackbar({ open: true, message: 'Feature Updated Successfully', type: 'success' }));
          navigate('/feature-manager');
        }
      });
    }
  };

  const handleRefineFilter = (obj) => {
    setCustomFilter(obj);
    setOpenRefineFilter(true);
  };

  const handleCloseRefineFilter = () => {
    setOpenRefineFilter(false);
  };

  const handleRefineFilterSave = (obj, operations) => {
    const updateData = customObject.data.map((object) => {
      if (object.custom_entity_id === obj.custom_entity_id) {
        object.operation_type = operations;
        if (object.operation_type.length) {
          object.allEvent = crudOperations.every((data) => {
            return object.operation_type.includes(data.value);
          });
          // object.allSource = otherOperations.every((data) => {
          //   return object.operation_type.includes(data.value);
          // });
        } else {
          object.allEvent = false;
          // object.allSource = false;
        }
      }
      return object;
    });
    const updateCustomObject = { ids: customObject.ids, data: updateData };
    setCustomObject(updateCustomObject);
    handleDisabled();
    setOpenRefineFilter(false);
  };

  // Icons
  const customTypes = {
    [entityName?.NESTED_FEATURE]: <AirlineStops />,
    [entityName?.CUSTOM_OBJECT]: <FitScreenOutlined />,
    [entityName?.LIGHTNING_PAGE]: <ViewComfyOutlined />,
    [entityName?.LIGHTNING_COMPONENT]: <ElectricBoltOutlined />,
    [entityName?.VISUALFORCE_PAGE]: <AspectRatioOutlined />,
    [entityName?.APEX_EXECUTION]: <TerminalOutlined />,
    [entityName?.APEX_CLASS]: <TerminalOutlined />,
    [entityName?.APEX_TRIGGER]: <TerminalOutlined />,
  };

  // Data
  const operationsTypes = useMemo(() => {
    const opTypes = operationTypeList.map((obj) => {
      switch (obj.id) {
        case 'nestedFeatures':
          obj.value = nestedFeatures;
          break;
        case 'customObject':
          obj.value = customObject;
          break;
        case 'lightingPages':
          obj.value = lightingPages;
          break;
        case 'lightningComponents':
          obj.value = lightningComponents;
          break;
        case 'visualForcePages':
          obj.value = visualForcePages;
          break;
        case 'apexExecutions':
          obj.value = apexExecutions;
          break;
        default:
      }
      return obj;
    });
    return opTypes;
  }, [nestedFeatures, customObject, lightingPages, lightningComponents, visualForcePages, apexExecutions]);

  useEffect(() => {
    if (redirectFromLogAnalyzer && Object.keys(editFeatureData).length) {
      Object.keys(editFeatureData).forEach((item) => {
        const ids = editFeatureData[item];
        handleSelect(ids, [item]);
      });
    }
  }, [editFeatureData, redirectFromLogAnalyzer, customEntityData?.customEntityList]);

  useEffect(() => {
    handleDisabled('preview');
  }, []);

  return {
    openPick,
    editData,
    name,
    openRefineFilter,
    type,
    label,
    customFilter,
    operationsTypes,
    disabled,
    previewDisabled,
    customTypes,
    isCopyClick,
    openPreview,
    previewData,
    handleClosePreview,
    handlePickOpen,
    handlePickClose,
    handleChange,
    handleSelect,
    handleRemove,
    handleSaveBtn,
    handleRefineFilter,
    handleCloseRefineFilter,
    handleRefineFilterSave,
    handlePreviewBtn,
  };
};

export default useAddEditFeature;
