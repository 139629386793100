/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
import React from 'react';
import { getIn } from 'formik';
import {
  Stack,
  Typography,
  RadioGroup,
  FormControl,
  Radio,
  FormControlLabel,
  Tooltip,
  Box,
  TextField,
  Collapse,
  useMediaQuery,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useSelector } from 'react-redux';
import CustomIcon from '../../../../components/CustomIcon/CustomIcon';
import { InfoChip } from '../../../../components/CustomChip';
import palette from '../../../../theme/palette';
import { toolTipText } from '../../../../utils/toolTipText';
import { healthScoreTargetMetricFeature, healthScoreTargetMetricPackage } from '../../constant';
import { SelectBox } from '../../../../components/FormComponents';
import { useRuleComponent } from './useRuleComponent';
import RangeSlider from '../../../../components/Histogram/RangeSlider';

function RuleComponent({
  index,
  ruleData,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleRemove,
  handleRuleChange,
  setFieldValue,
  featureManagerListData,
  insert,
}) {
  const getFieldValue = (fieldName) => getIn(values, fieldName);
  const getTouched = (fieldName) => getIn(touched, fieldName);
  const getError = (fieldName) => getIn(errors, fieldName);

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const ruleType = `healthScoreRules[${index}].ruleType`;
  const targetMetric = `healthScoreRules[${index}].targetMetric`;
  const thresholdMin = `healthScoreRules[${index}].threshold[0]`;
  const thresholdMax = `healthScoreRules[${index}].threshold[1]`;
  const threshold = `healthScoreRules[${index}].threshold`;
  const rulePoints = `healthScoreRules[${index}].rulePoints`;
  const ruleOption = `healthScoreRules[${index}].ruleOption`;
  const isOpenGraph = `healthScoreRules[${index}].isOpenGraph`;

  const touchedRuleType = getTouched(ruleType);
  const errorRuleType = getError(ruleType);
  const touchedTargetMetric = getTouched(targetMetric);
  const errorTargetMetric = getError(targetMetric);
  const touchedThreshold = getTouched(`healthScoreRules[${index}].threshold`);
  const errorThreshold = getError(`healthScoreRules[${index}].threshold`);
  const touchedRulePoints = getTouched(rulePoints);
  const errorRulePoints = getError(rulePoints);
  const touchedRuleOption = getTouched(ruleOption);
  const errorRuleOption = getError(ruleOption);
  const {
    str, useStyles, graphData, handleViewGraph, toggle, isRuleComplete, preventInvalidInput, handleDuplicateRule,
  } = useRuleComponent({
    ruleData,
    featureManagerListData,
    values,
    setFieldValue,
    errors,
    index,
    insert,
  });
  const { run } = useSelector((state) => state.tourGuide);
  const classes = useStyles();
  return (
    <Stack key={index}>
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1} width="calc(100% - 120px)">
          <Typography
            variant="subtitle1"
            sx={{
              backgroundColor: isRuleComplete ? palette.success.light : palette.error.light,
              borderRadius: '5px',
              padding: '4px 10px',
            }}
          >
            Rule {index + 1}
          </Typography>
          {!isSmallScreen && <InfoChip label={str} fontWeight="bold" hidden={str?.length <= 0} />}
        </Stack>

        <Stack direction="row" alignItems="center">
          <CustomIcon
            className="tg-new-kpi-score-graph"
            Icon={getFieldValue(isOpenGraph) && toggle ? AssessmentIcon : AssessmentOutlinedIcon}
            disabled={!getFieldValue(targetMetric)}
            onClick={() => handleViewGraph(index, getFieldValue(isOpenGraph) && toggle ? 0 : 1)}
            tooltip={toolTipText.healthInsights.SHOW_DISTRIBUTION_SUCCESS}
          />
          <CustomIcon
            className="tg-new-kpi-score-duplicate"
            Icon={ContentCopyIcon}
            onClick={() => handleDuplicateRule(index)}
            tooltip={toolTipText.healthInsights.DUPLICATE_RULE}
          />
          <CustomIcon
            className="tg-new-kpi-score-remove"
            Icon={RemoveCircleOutlineIcon}
            disabled={values?.healthScoreRules?.length === 1}
            onClick={() => handleRemove(index)}
            tooltip={values?.healthScoreRules?.length === 1 ? toolTipText.healthInsights.REMOVE_RULE_SUCCESS : toolTipText.healthInsights.REMOVE_RULE_FAIL}
          />
        </Stack>
      </Stack>

      <Stack className="tg-new-kpi-score-rule-type" direction={isSmallScreen ? 'column' : 'row'} alignItems="center" spacing={2}>
        {isSmallScreen ? (
          <SelectBox
            title="Select rule type"
            name={ruleType}
            data={[
              { id: 1, value: 'Package Engagement', name: 'Package Engagement' },
              { id: 2, value: 'Feature Usage', name: 'Feature Usage' },
            ]}
            value={getFieldValue(ruleType)}
            onChange={({ target: { value } }) => handleRuleChange(index, value, setFieldValue)}
            disabled={false}
          />
        ) : (
          <>
            <Typography>Pick The Rule Type : </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name={ruleType}
              helperText={touchedRuleType && errorRuleType ? errorRuleType : ''}
              value={getFieldValue(ruleType)}
              onChange={({ target: { value } }) => handleRuleChange(index, value, setFieldValue)}
              onBlur={handleBlur}
            >
              <FormControlLabel
                value="Package Engagement"
                control={<Radio />}
                label="Package Engagement"
              />
              <FormControlLabel
                value="Feature Usage"
                control={<Radio />}
                label="Feature Usage"
              />
            </RadioGroup>
          </>
        )}
        {touchedRuleType && errorRuleType && <Typography color="red">{errorRuleType}</Typography>}
      </Stack>

      <Stack direction={isSmallScreen ? 'column' : 'row'} alignItems="center" spacing={2} paddingTop={2}>
        {ruleData.ruleType === 'Feature Usage' && (
        <FormControl style={{ width: isSmallScreen ? '100%' : '20%' }}>
          <SelectBox
            title="Features"
            data={featureManagerListData?.map(({ feature_id, feature_name }) => ({
              id: feature_id,
              value: feature_id,
              name: feature_name,
            }))}
            name={ruleOption}
            value={getFieldValue(ruleOption)}
            helperText={touchedRuleOption && errorRuleOption ? errorRuleOption : ''}
            error={Boolean(touchedRuleOption && errorRuleOption)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>
        )}
        {run || (ruleData.ruleType?.length && (ruleData.ruleOption?.length || ruleData.ruleType === 'Package Engagement')) ? (
          <SelectBox
            className="tg-new-kpi-score-metric"
            width={isSmallScreen ? '100%' : '20%'}
            data={ruleData.ruleType === 'Feature Usage'
              ? healthScoreTargetMetricFeature
              : healthScoreTargetMetricPackage}
            title="Target Metric"
            name={targetMetric}
            value={getFieldValue(targetMetric)}
            helperText={touchedTargetMetric && errorTargetMetric ? errorTargetMetric : ''}
            label="Age"
            error={Boolean(touchedTargetMetric && errorTargetMetric)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        ) : null}
        {(run || ruleData.targetMetric?.length) ? (
          <Box
            className="tg-new-kpi-score-range"
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'flex-start' : 'center'}
            justifyContent={isSmallScreen ? 'flex-start' : 'space-evenly'}
            width="auto"
            gap={2}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography>Range for Scoring</Typography>
              <Tooltip title={toolTipText.healthInsights.THRESHOLD_INFO}>
                <InfoIcon color="primary" />
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <FormControl style={{ width: isSmallScreen ? '100%' : 'auto' }}>
                <TextField
                  disableRestoreFocus
                  id="outlined-number"
                  placeholder="Minimum"
                  type="number"
                  name={thresholdMin}
                  onKeyDown={(e) => preventInvalidInput(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={getFieldValue(thresholdMin)}
                  error={Boolean(touchedThreshold && errorThreshold)}
                  onChangeCapture={handleChange}
                  inputProps={{ min: 0 }}
                />
              </FormControl>
              {isSmallScreen ? <span> - </span> : <span> to </span>}
              <FormControl style={{ width: isSmallScreen ? '100%' : 'auto' }}>
                <TextField
                  disableRestoreFocus
                  id="outlined-number"
                  placeholder="Ideal"
                  type="number"
                  name={thresholdMax}
                  onKeyDown={(e) => preventInvalidInput(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: 0 }}
                  value={getFieldValue(thresholdMax)}
                  error={Boolean(touchedThreshold && errorThreshold) || ruleData.threshold[1] <= ruleData.threshold[0]}
                  onChangeCapture={handleChange}
                />
              </FormControl>
            </Box>
          </Box>
        ) : null}
      </Stack>

      {ruleData.rulePoints || (ruleData.threshold?.length === 2 && !(ruleData.threshold[1] <= ruleData.threshold[0])) ? (
        <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} alignItems="center" gap="10px" marginTop={1}>
          {isSmallScreen ? (
            <SelectBox
              title="Rule weightage"
              value={getFieldValue(rulePoints)}
              name={rulePoints}
              data={[
                { id: 1, value: '1', name: 'Low' },
                { id: 2, value: '2', name: 'Medium' },
                { id: 3, value: '3', name: 'High' },

              ]}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ minWidth: 120, marginTop: 8 }}
              disabled={false}
            />

          ) : (
            <>
              <Typography>Rule Weightage : </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-radio--group-label"
                name={rulePoints}
                value={getFieldValue(rulePoints)}
                helperText={touchedRulePoints && errorRulePoints ? errorRulePoints : ''}
                error={Boolean(touchedRulePoints && errorRulePoints)}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ flexWrap: 'nowrap' }}
              >
                <Tooltip title={toolTipText.healthInsights.LOW_WEIGHTAGE}><FormControlLabel value="1" control={<Radio size="small" />} label="Low" /></Tooltip>
                <Tooltip title={toolTipText.healthInsights.MEDIUM_WEIGHTAGE}><FormControlLabel value="2" control={<Radio size="small" />} label="Medium" /></Tooltip>
                <Tooltip title={toolTipText.healthInsights.HIGH_WEIGHTAGE}><FormControlLabel value="3" control={<Radio size="small" />} label="High" /></Tooltip>
              </RadioGroup>
            </>
          )}
        </Box>
      ) : null}

      <Collapse
        in={getFieldValue(isOpenGraph) && getFieldValue(targetMetric)?.length && toggle}
        className={getFieldValue(isOpenGraph) ? classes.histogramContainer : classes.histogramClosed}
        style={{ transition: 'all 0.2s' }}
      >
        <RangeSlider
          threshold={getFieldValue(threshold)}
          graphData={graphData}
          setFieldValue={setFieldValue}
          index={index}
          targetMetric={getFieldValue(targetMetric)}
        />
      </Collapse>
    </Stack>
  );
}

export default React.memo(RuleComponent);
