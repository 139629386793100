import { Box, Grid } from '@mui/material';
import { InfoChip } from '../../../../components/CustomChip';
import { SelectBox, Switch } from '../../../../components/FormComponents';

function ActiveUserConfigForm({ filteredFeatures, formik }) {
  const { handleChange, values } = formik;

  return (
    <Grid container gap={1} p={2}>
      <Grid item container xs={12} mb={2} justifyContent="space-between" alignItems="center" gap={1}>
        <Box width={{ sm: '60%', xl: 'auto' }}>
          <InfoChip icon label="Choose metric for High-Risk accounts." />
        </Box>
        <Box width="190px">
          <Switch
            name="config"
            label="Enable Config"
            value={values.config}
            onChange={handleChange}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <SelectBox
          disabled={!values.config}
          name="selectedKpi"
          title="Select Feature"
          onChange={handleChange}
          data={filteredFeatures}
          value={values.selectedKpi}
        />
      </Grid>
    </Grid>
  );
}

export default ActiveUserConfigForm;
