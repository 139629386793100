import { Container } from '@mui/material';
import { PackageManager } from '../sections/PackageManager';
import Metatags from '../components/MetaTags/MetaTags';

function PackageManagementPage({ permissions }) {
  return (
    <>
      <Metatags metaTagName="Package Manager | ISV Insights" />
      <Container maxWidth="xl">
        <PackageManager permissions={permissions} />
      </Container>
    </>
  );
}

export default PackageManagementPage;
