import {
  Grid, Box, Typography, Stack, Tooltip, Button,
  Dialog,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { useMemo } from 'react';
import { generateGraphLabels, getPartialDataLabel } from '../../../../utils/getDate';
import { getChartOptions, partialDataBackground } from '../../../../utils/chartConfigs';
import { uniqueArr } from '../../../../utils/methods';
import { BAR_GRAPH } from '../../../../utils/constants';
import FeatureUsageHeader from '../../../FeatureUsage/components/FeatureUsageCard/FeatureUsageHeader';
import FeatureUsageBody from '../../../FeatureUsage/components/FeatureUsageCard/FeatureUsageBody';
import { useChart } from '../../../../components/chart';
import { CustomNoDataOverlay } from '../../../../components/CustomNoDataOverlay';

function DialogContentElement({ data }) {
  if (data?.id) {
    return (
      <Grid container gap={2}>
        <Grid
          item
          xs={12}
          key={data.id}
          sx={{
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
            borderRadius: '6px',
          }}
        >
          <Accordion
            disableGutters
            expanded
          >
            <AccordionSummary>
              {data?.title && <Typography>{data?.title}</Typography>}
              {data.header ? data.header : null}
            </AccordionSummary>
            <AccordionDetails>
              {data.body}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    );
  }
  return (
    <CustomNoDataOverlay message="No Report found for this Feature" />
  );
}

function CustomDialogBox({
  title, open, handleClose, content, actions, table, formRef,
}) {
  const handleActionClick = () => {
    if (formRef?.current) {
      formRef.current.handleSubmit();
    } else {
      actions.onClick();
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false} sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <DialogTitle align="center">
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <Tooltip title={title}>
            <Typography noWrap variant="h6">{title}</Typography>
          </Tooltip>
          <IconButton onClick={handleClose} sx={{ color: (theme) => theme.palette.grey[500], transform: 'translateX(10px)' }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: table ?? '10px 24px' }}>
        {content}
      </DialogContent>
      {actions && (
      <DialogActions sx={{ m: 1, justifyContent: 'space-between' }}>
        <Typography>{actions.info || ''}</Typography>
        <Button variant="contained" size="small" mt={1} disabled={actions.disabled} onClick={handleActionClick}>{actions.title}</Button>
      </DialogActions>
      )}
    </Dialog>
  );
}

export default function KPIPreview(props) {
  const {
    open,
    handleClose,
    previewData,
    view = 'month',
    dashboard = true,
    graphTitle = null,
    previewType = 'popup',
  } = props;
  // Graph functions
  const operationCountDataset = (entityOperationCounts, viewOption) => {
    const mergedCounts = {};

    entityOperationCounts?.forEach((item) => {
      const existingCount = mergedCounts[item.type]?.count || {};
      Object.entries(item.count).forEach(([key, value]) => {
        existingCount[key] = (existingCount[key] || 0) + Number(value);
      });
      mergedCounts[item.type] = { ...item, count: existingCount };
    });

    const result = Object.values(mergedCounts);

    const dataset = result.map((operation) => {
      const opLabels = Object.keys(entityOperationCounts[0]?.count);
      const partialDateOp = getPartialDataLabel(opLabels, viewOption);

      return {
        label: operation.type,
        data: Object.values(operation.count),
        backgroundColor: opLabels.map((dateStr) => {
          return dateStr === partialDateOp
            ? BAR_GRAPH.PartialData
            : BAR_GRAPH[operation.type];
        }),
        barThickness: 23,
        stack: 'operation count',
      };
    });

    return dataset;
  };

  const generateDatasets = (data, chart, viewOption) => {
    const operationCounts = Object.values(data.operationCount);
    const entityOperationCounts = Object.values(data.entityOperationCount);
    const userCountData = Object.values(data.userCount);
    const operationPerUser = operationCounts.map((n, i) => (n / userCountData[i]).toFixed(0));
    const opLabels = Object.keys(data?.operationCount);
    const userLabels = Object.keys(data?.userCount);
    const partialDateOp = getPartialDataLabel(opLabels, viewOption);
    const partialDateUser = getPartialDataLabel(userLabels, viewOption);

    switch (chart) {
      case 'bar':
        return operationCountDataset(entityOperationCounts, viewOption);
      case 'combo':
        return [
          {
            label: 'Users',
            data: userCountData,
            backgroundColor: partialDataBackground(userLabels, partialDateUser),
            yAxisID: 'user',
            order: 2,
            barThickness: 23,
          },
          {
            label: 'Operations per User',
            data: operationPerUser,
            backgroundColor: partialDataBackground(opLabels, partialDateOp, '#FFFFFF'),
            borderColor: '#294E99',
            type: 'line',
            yAxisID: 'operation',
            order: 1,
            borderWidth: 2.5,
          },
        ];
      default:
        return {};
    }
  };

  const chartOptionsFn = useChart();

  const getChartData = ({
    feature, config, type, viewOption,
  }) => {
    const localView = viewOption;
    const datasets = generateDatasets(feature, config.chart, localView);
    const options = getChartOptions(config);
    const chartOptions = chartOptionsFn(options);

    if (feature) {
      let keys;
      if (type === 'op') {
        const keyObj = feature?.entityOperationCount[0]?.count;
        keys = Object.keys(keyObj);
      } else {
        keys = Object.keys(feature.userCount);
        keys = uniqueArr(keys);
      }
      const labels = generateGraphLabels(keys, localView);

      return {
        labels,
        datasets,
        chartOptions,
      };
    }
    return {};
  };

  const handleOnRowClick = () => {};

  const kpiInfo = useMemo(() => {
    const { totalActiveSeats, totalActiveAccount, featureInfo = {} } = previewData;
    const {
      featureId: id, accountCount,
    } = featureInfo;
    const userCount = featureInfo.userCount || {};
    const operationCount = featureInfo.operationCount || {};
    const userCountLen = Object.keys(userCount).length;
    const operationCountLen = Object.keys(operationCount).length;

    if (userCountLen && operationCountLen) {
      const localObj = {
        id: previewType === 'component' ? 'new' : id,
        header: (
          <FeatureUsageHeader
            feature={featureInfo}
            permissions={{ read: true, write: false }}
            dashboard={dashboard}
            accountCount={accountCount}
            handleOnRowClick={handleOnRowClick}
          />
        ),
        body: (
          <FeatureUsageBody
            feature={featureInfo}
            dashboard={dashboard}
            accountCount={accountCount}
            userCountLen={userCountLen}
            operationCountLen={operationCountLen}
            totalActiveSeats={totalActiveSeats}
            totalActiveAccounts={totalActiveAccount}
            getChartData={getChartData}
            viewOption={view}
            graphTitle={graphTitle}
          />
        ),
      };

      return localObj;
    }
    const name = featureInfo.featureName;
    return name;
  });

  if (previewType === 'component') {
    return (
      <Box>
        <DialogContentElement data={kpiInfo} />
      </Box>
    );
  }

  return (
    <Box sx={{ minWidth: 200 }}>
      <CustomDialogBox
        title="Feature Preview"
        open={open}
        handleClose={handleClose}
        content={(
          <DialogContentElement
            data={kpiInfo}
          />
        )}
        actions={{ title: 'close', onClick: handleClose }}
      />
    </Box>
  );
}
