import { Container } from '@mui/material';
import { TenantDetail } from '../sections/TenantDetail';
import Metatags from '../components/MetaTags/MetaTags';

function TenantDetailsPage() {
  return (
    <>
      <Metatags metaTagName="Tenant Details | ISV Insights" />
      <Container maxWidth="xl">
        <TenantDetail />
      </Container>
    </>
  );
}

export default TenantDetailsPage;
