import { Box, Grid } from '@mui/material';
import { useMemo } from 'react';
import useAuthorization from '../../../../hooks/useAuthorization';
import { MutedText } from '../../../../components/CustomTypography';
import { SelectBox, Switch } from '../../../../components/FormComponents';
import { InfoChip } from '../../../../components/CustomChip';
import TextFieldWithError from '../../../../components/FormComponents/TextfieldWithError';

function EditUser(props) {
  const { formik } = props;

  const {
    values, errors, handleChange, touched, getIn,
  } = formik;

  const userGroups = [
    { id: 2, value: 'user', name: 'User' },
    { id: 1, value: 'admin', name: 'Admin' },
  ];

  const { userInfo } = useAuthorization();
  const { email } = userInfo;
  const isDisabled = useMemo(() => values.email === email, [email]);

  return (
    <Grid container gap={1} p={2}>
      <Grid item container xs={12} mb={2} justifyContent="space-between" alignItems="center" gap={1}>
        <Box width={{ sm: '60%', xl: 'auto' }}>
          <InfoChip
            icon
            type="multiline"
            label="If disabled, user will not be able to access ISV Insights."
          />
        </Box>
        <Box width="170px">
          <Switch
            name="status"
            label="Enable User"
            value={values.status}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TextFieldWithError
          name="email"
          label="Email Address"
          value={values.email}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5', pointerEvents: 'none' },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldWithError
          name="name"
          label="Name"
          value={values.name}
          onChange={handleChange}
          error={touched && getIn(errors, 'name')}
        />
      </Grid>

      <Grid item xs={12} mb={2}>
        <MutedText text="User group will define what features that particular user can access." sxProps={{ marginLeft: '8px' }} />
        <SelectBox
          title="User Group"
          name="group"
          data={userGroups}
          value={values.group}
          fullWidth
          onChange={handleChange}
          error={touched && getIn(errors, 'group')}
          disabled={isDisabled}
          spacing
        />
      </Grid>
    </Grid>
  );
}

export default EditUser;
