import Button from '@mui/material/Button';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import { Tooltip } from '@mui/material';
import { fCommaSeparated } from './formatNumber';

export const dashboardTopFeatureColumns = [
  {
    field: 'featureName',
    headerName: 'Feature',
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    field: 'userCount',
    headerName: 'Users',
    flex: 1,
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'center-align--header',
    renderCell: (params) => fCommaSeparated(params.value),
  },
  {
    field: 'operationCount',
    headerName: 'Operations',
    minWidth: 150,
    flex: 1,
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'center-align--header',
    renderCell: (params) => fCommaSeparated(params.value),
  },
  {
    field: 'accountCount',
    headerName: 'Accounts',
    flex: 1,
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'center-align--header',
    renderCell: (params) => fCommaSeparated(params.value),
  },
];

export const accountTopFeatureColumns = [
  {
    field: 'featureName',
    headerName: 'Feature',
    minWidth: 250,
    sortable: false,
  },
  {
    field: 'userCount',
    headerName: 'Users',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'center-align--header',
    renderCell: (params) => fCommaSeparated(params.row.userCount),
  },
  {
    field: 'operationCount',
    headerName: 'Operations',
    minWidth: 140,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'center-align--header',
    renderCell: (params) => fCommaSeparated(params.row.operationCount),
  },
];

export const objectAnalyserColumns = [
  {
    field: 'date', headerName: 'Date (UTC)', minWidth: 100, flex: 1,
  },
  {
    field: 'customentity',
    headerName: 'Custom Objects',
    minWidth: 250,
    flex: 1,
    renderCell: (params) => (
      <Button
        variant="outlined"
        disabled
        startIcon={<ViewInArOutlinedIcon />}
      >
        {params.value}
      </Button>
    ),
  },
  {
    field: 'package',
    headerName: 'Package',
    minWidth: 100,
    flex: 1,
    align: 'left',
  },
  {
    field: 'CREATES',
    headerName: 'Creates',
    minWidth: 100,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (params.value ? params.value : '-'),
  },
  {
    field: 'READS',
    headerName: 'Reads',
    minWidth: 100,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (params.value ? params.value : '-'),
  },
  {
    field: 'UPDATES',
    headerName: 'Updates',
    minWidth: 100,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (params.value ? params.value : '-'),
  },
  {
    field: 'DELETES',
    headerName: 'Deletes',
    minWidth: 100,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (params.value ? params.value : '-'),
  },
  {
    field: 'total',
    headerName: 'Total',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (params.value ? params.value : '-'),
  },
];

export const riskAccountsColumns = (field) => {
  const columnInfo = [
    {
      field: 'accountName',
      headerName: 'Name',
      sortable: true,
      filterable: false,
      flex: 1.2,
    },
    {
      field: 'seatsAssigned',
      headerName: 'Seats',
      flex: 1,
      sortable: true,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field: 'healthScoreTrendThumbnail',
      headerName: 'Trend',
      sortable: false,
      filterable: false,
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field,
      headerName: 'Signal',
      sortable: true,
      filterable: false,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      renderHeader: () => (
        <Tooltip title="Heartbeat">
          <img
            alt="wt"
            src="/heartSignal.svg"
            width="80%"
          />
        </Tooltip>
      ),
    },
    {
      field: 'healthScore',
      headerName: 'Score',
      sortable: true,
      filterable: false,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
  ];
  return columnInfo;
};

export const expiredAccountColumns = (field) => {
  const columnInfo = [
    {
      field: 'accountName',
      headerName: 'Name',
      sortable: true,
      filterable: false,
      flex: 1.2,
    },
    {
      field: 'renewalDate',
      headerName: 'Expiration Date',
      flex: 1,
      sortable: true,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field: 'healthScoreTrendThumbnail',
      headerName: 'Trend',
      sortable: false,
      filterable: false,
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field,
      headerName: 'Signal',
      sortable: true,
      filterable: false,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      renderHeader: () => (
        <Tooltip title="Heartbeat">
          <img
            alt="wt"
            src="/heartSignal.svg"
            width="80%"
          />
        </Tooltip>
      ),
    },
    {
      field: 'healthScore',
      headerName: 'Score',
      sortable: true,
      filterable: false,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
  ];
  return columnInfo;
};

export const decliningAccountColumns = (field) => {
  const columnInfo = [
    {
      field: 'accountName',
      headerName: 'Name',
      sortable: true,
      filterable: false,
      flex: 1.2,
    },
    {
      field: 'seatsAssigned',
      headerName: 'Seats',
      flex: 1,
      sortable: true,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field: 'healthScoreTrendThumbnail',
      headerName: 'Trend',
      sortable: false,
      filterable: false,
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field,
      headerName: 'Signal',
      sortable: true,
      filterable: false,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      renderHeader: () => (
        <Tooltip title="Heartbeat">
          <img
            alt="wt"
            src="/heartSignal.svg"
            width="80%"
          />
        </Tooltip>
      ),
    },
    {
      field: 'healthScore',
      headerName: 'Score',
      sortable: true,
      filterable: false,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
  ];
  return columnInfo;
};

export const trendAccountColumns = (field) => {
  const columnInfo = [
    {
      field: 'accountName',
      headerName: 'Name',
      sortable: true,
      filterable: false,
      flex: 1.2,
    },
    {
      field: 'seatsAssigned',
      headerName: 'Seats',
      flex: 1,
      sortable: true,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field: 'healthScoreTrendThumbnail',
      headerName: 'Trend',
      sortable: false,
      filterable: false,
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field,
      headerName: 'Signal',
      sortable: true,
      filterable: false,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      renderHeader: () => (
        <Tooltip title="Heartbeat">
          <img
            alt="wt"
            src="/heartSignal.svg"
            width="80%"
          />
        </Tooltip>
      ),
    },
    {
      field: 'healthScore',
      headerName: 'Score',
      sortable: true,
      filterable: false,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
  ];
  return columnInfo;
};
