import Metatags from '../../../components/MetaTags/MetaTags';
import ResetPasswordForm from './ResetPasswordForm';
import useResetPassword from './useResetPassword';

export default function ResetPassword() {
  const {
    initialValues,
    auth,
    handleSubmit,
    handleBackToLogin,
    validationSchema,
  } = useResetPassword();

  return (
    <>
      <Metatags metaTagName="Reset Password | ISV Insights" />
      <ResetPasswordForm
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        handleBackToLogin={handleBackToLogin}
        auth={auth}
      />
    </>
  );
}
