const dashboardSteps = [
  {
    target: '.tg-package-header',
    content: 'By selecting a package, you can customize your dashboard experience and access relevant data that aligns with your business goals.',
    placement: 'auto',
    pathname: '/dashboard',
    disableBeacon: true,
  },
  {
    target: '.tg-watch-list',
    content: 'The Watch List feature enables you to keep track of predefined items, such as accounts at risk. You can also create custom entries to monitor specific accounts or metrics of interest.',
    placement: 'auto',
  },
  {
    target: '.tg-account-stats',
    content: 'At the top of the dashboard, you\'ll find four summary cards that provide key information at a glance.',
    placement: 'auto',
  },
  {
    target: '.tg-dashboard-cards',
    content: 'Below the summary cards, you\'ll find several detailed information cards, each providing insights into different aspects of your accounts.',
    placement: 'bottom',
    next: '/account-list',
  },
];

const accountListSteps = [
  {
    target: '.tg-filter-view',
    content: 'Use this dropdown to switch between predefined views or the custom views you have created.',
    placement: 'auto',
    prev: '/dashboard',
  },
  {
    target: '.tg-filters',
    content: 'Select filters to narrow down the accounts list based on specific criteria such as account size, status, or activity level.',
    placement: 'auto',
  },
  {
    target: '.tg-custom-view',
    content: 'Select your desired filters and click this button to save them as a custom view for easy access in the future.',
    placement: 'auto',
  },
  {
    target: '.tg-trends',
    content: 'Displays a graph showing whether the account is stable, declining, or trending based on recent data.',
    placement: 'auto',
  },
  {
    target: '.tg-heartbeat',
    content: 'Displays an icon indicating the account\'s status: stable, declining, or trending.',
    placement: 'auto',
  },
  {
    target: '.tg-score',
    content: 'Shows the KPI score of the account over a one-month period, calculated using the primary KPI score configuration among all available configurations.',
    placement: 'auto',
  },
  {
    target: '.tg-export-list',
    content: 'Click this button to export the accounts list. If filters are applied, only the filtered list will be exported; if no filters are selected, the entire list will be exported.',
    placement: 'auto',
    next: '/feature-usage',
  },
];

const featureUsageSteps = [
  {
    target: '.tg-sortby-filter',
    content: 'Select how you want to sort the data—by the number of users, operations, or account count.',
    placement: 'auto',
    prev: '/account-list',
  },
  {
    target: '.tg-view-filter',
    content: 'Choose the time period for the data—view it monthly, weekly, or daily.',
    placement: 'auto',
  },
  {
    target: '.tg-kpi-filter',
    content: 'Select specific Features to view their data. If none are selected, data for all Features will be displayed.',
    placement: 'auto',
  },
  {
    target: '.tg-expand-all',
    content: 'Use this toggle to expand or collapse all accordions at once. By default, only pinned Features are expanded.',
    placement: 'auto',
    next: '/feature-manager',
  },
];

const featureManagerSteps = [
  {
    target: '.tg-kpi-search',
    content: 'Use this search box to quickly locate a Feature by name or keyword.',
    placement: 'auto',
    prev: '/feature-usage',
  },
  {
    target: '.tg-new-kpi',
    content: 'Click this button to create a new Feature and track performance metrics.',
    placement: 'auto',
  },
  {
    target: '.tg-kpi-pin-unpin',
    content: 'Use the pin/unpin icon to pin or unpin the Feature.',
    placement: 'auto',
  },
  {
    target: '.tg-kpi-heading',
    content: 'Click on the Feature to expand and view its details.',
    placement: 'auto',
  },
  {
    target: '.tg-kpi-copy',
    content: 'Use this option to duplicate an existing Feature for easier setup.',
    placement: 'auto',
  },
  {
    target: '.tg-kpi-edit',
    content: 'Click here to modify the details of an existing Feature as needed.',
    placement: 'auto',
  },
  {
    target: '.tg-kpi-delete',
    content: 'Delete a Feature permanently using this button. This action cannot be undone.',
    placement: 'auto',
    next: '/kpi-score-configuration',
  },
];

const kpiScoreConfigSteps = [
  {
    target: '.tg-new-kpi-score',
    content: 'Click this button to create a new KPI Score Configuration.',
    placement: 'auto',
    prev: '/feature-manager',
  },
  {
    target: '.tg-kpi-score-primary',
    content: 'Set a KPI score as primary to make it the default for KPI score calculations.',
    placement: 'auto',
  },
  {
    target: '.tg-kpi-score-copy',
    content: 'Use this option to duplicate an existing KPI Score Config for easier setup.',
    placement: 'auto',
  },
  {
    target: '.tg-kpi-score-edit',
    content: 'Click here to modify the details of an existing KPI Score Config as needed.',
    placement: 'auto',
  },
  {
    target: '.tg-kpi-score-delete',
    content: 'A primary KPI score or one with associated parameters cannot be deleted. Use this button to permanently delete a KPI score configuration if eligible.',
    placement: 'auto',
    next: '/kpi-score-configuration/new-health-score',
  },
];

const newKpiScoreConfigSteps = [
  {
    target: '.tg-new-kpi-score-label',
    content: 'A KPI score config label should be unique.',
    placement: 'auto',
    prev: '/kpi-score-configuration',
  },
  {
    target: '.tg-new-kpi-score-params',
    content: 'Set some parameters to make this KPI score config as primary to accounts that follow the criteria.',
    placement: 'auto',
  },
  {
    target: '.tg-new-kpi-score-rules',
    content: 'Setup multiple rules for KPI score calculation.',
    placement: 'auto',
  },
  {
    target: '.tg-new-kpi-score-graph',
    content: 'After selecting a target metric, use this button to visualize the distribution of different accounts w.r.t. how they measure on the given metric.',
    placement: 'auto',
  },
  {
    target: '.tg-new-kpi-score-duplicate',
    content: 'Use this button to duplicate a rule and modify it according to your needs.',
    placement: 'auto',
  },
  {
    target: '.tg-new-kpi-score-remove',
    content: 'Use this button to remove a rule which is not required.',
    placement: 'auto',
  },
  {
    target: '.tg-new-kpi-score-rule-type',
    content: 'Select the rule type: choose either "Package Engagement" or "Feature Usage" to determine how the KPI score will be calculated.',
    placement: 'auto',
  },
  {
    target: '.tg-new-kpi-score-metric',
    content: 'Choose a target metric that will be used to calculate the KPI score. This metric will determine the basis for scoring.',
    placement: 'auto',
  },
  {
    target: '.tg-new-kpi-score-range',
    content: 'Set a minimum and maximum value for the target metric you selected. The KPI score will be calculated based on this value range.',
    placement: 'auto',
  },
];

// exports

export const allSteps = {
  '/dashboard': dashboardSteps,
  '/account-list': accountListSteps,
  '/feature-usage': featureUsageSteps,
  // setup pages
  '/feature-manager': featureManagerSteps,
  '/kpi-score-configuration': kpiScoreConfigSteps,
  '/kpi-score-configuration/new-health-score': newKpiScoreConfigSteps,
};

export const setupRoutes = ['/feature-manager', '/kpi-score-configuration', '/kpi-score-configuration/new-health-score'];
