import { Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import palette from '../../../theme/palette';
import { SelectBox } from '../../../components/FormComponents';
import { formikForm } from '../../../hoc';
import { MutedText } from '../../../components/CustomTypography';
import TextFieldWithError from '../../../components/FormComponents/TextfieldWithError';

function ResetPasswordForm({ formik, auth, handleBackToLogin }) {
  const {
    handleChange, touched, errors, getIn, values,
  } = formik;

  return (
    <Grid container>
      <Stack>
        <Typography variant="h4">Reset Password</Typography>
        <MutedText text="Please provide your email for the verification code." />
      </Stack>
      <TextFieldWithError
        name="email"
        onChange={handleChange}
        label="Email Address"
        error={touched.email && getIn(errors, 'email')}
      />
      {/* Multi Tenant */}
      {auth.user?.tenantList && (
        <Grid item xs={12}>
          <MutedText text="Choose a tenant in which you want to login." sxProps={{ marginLeft: '8px' }} />
          <SelectBox
            title="Choose Tenant"
            name="tenant"
            value={getIn(values, 'tenant') ?? auth?.user?.tenantList[0].value}
            data={auth?.user?.tenantList}
            fullWidth
            onChange={handleChange}
            error={getIn(touched, 'tenant') && getIn(errors, 'tenant')}
            spacing
          />
        </Grid>
      )}
      {/* Action Buttons */}
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Link onClick={handleBackToLogin} to="/login" variant="body2" style={{ textDecoration: 'none', color: palette.primary.mainColor }}>
          Back To Login !
        </Link>
      </Grid>

      <Grid item xs={12}>
        <LoadingButton
          color="primary"
          loading={auth?.loading || false}
          variant="contained"
          fullWidth
          sx={{ my: '20px' }}
          type="submit"
        >
          Reset
        </LoadingButton>
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        Don&apos;t have an account ?
        {' '}
        <a href="https://isvinsights.ai/contact-us" variant="body2" style={{ textDecoration: 'none', color: palette.primary.mainColor }} target="_blank" rel="noreferrer">Contact Us</a>
      </Grid>
    </Grid>
  );
}

export default formikForm(ResetPasswordForm);
