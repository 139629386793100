import { Container } from '@mui/material';
import { Profile } from '../sections/Profile';
import Metatags from '../components/MetaTags/MetaTags';

export default function ProfilePage({ permissions }) {
  return (
    <>
      <Metatags metaTagName="My Profile | ISV Insights" />
      <Container maxWidth="xl">
        <Profile permissions={permissions} />
      </Container>
    </>
  );
}
