import { LoadingButton } from '@mui/lab';
import {
  Grid, IconButton, InputAdornment, Stack, Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';
import { SelectBox, TextFieldWithError } from '../../../components/FormComponents';
import { MutedText } from '../../../components/CustomTypography';
import { formikForm } from '../../../hoc';
import palette from '../../../theme/palette';

function LoginForm(props) {
  const {
    formik, auth, showPassword, handleTogglePassword, isRequiredForLogin,
  } = props;

  const {
    errors, handleChange, touched, getIn, values,
  } = formik;

  return (
    <Grid container>
      <Stack>
        <Typography variant="h4">Login</Typography>
        <MutedText text="Please enter your email to login." />
      </Stack>

      <TextFieldWithError
        name="email"
        onChange={handleChange}
        label="Email Address"
        error={getIn(touched, 'email') && getIn(errors, 'email')}
        InputProps={
          isRequiredForLogin('login') && {
            readOnly: true,
            style: { backgroundColor: '#f5f5f5', pointerEvents: 'none' },
          }
        }
      />

      {/* Tenant SelectBox */}
      {isRequiredForLogin('multiTenant') && (
        <Grid item xs={12}>
          <MutedText text="Choose a tenant in which you want to login." sxProps={{ marginLeft: '8px' }} />
          <SelectBox
            title="Choose Tenant"
            name="tenant"
            value={getIn(values, 'tenant') ?? auth?.user?.tenantList[0].value}
            data={auth?.user?.tenantList}
            fullWidth
            onChange={handleChange}
            error={getIn(touched, 'tenant') && getIn(errors, 'tenant')}
            spacing
          />
        </Grid>
      )}

      {/* Password TextField */}
      {isRequiredForLogin('login') && (
        <TextFieldWithError
          error={getIn(touched, 'password') && getIn(errors, 'password')}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton style={{ color: 'grey' }} onClick={handleTogglePassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}

      {/* Action Buttons */}
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Link to="/reset-password" variant="body2" style={{ textDecoration: 'none', color: palette.primary.mainColor }}>
          Forgot Password ?
        </Link>
      </Grid>

      <Grid item xs={12}>
        <LoadingButton
          color="primary"
          loading={auth?.loading || false}
          variant="contained"
          fullWidth
          sx={{ my: '20px' }}
          type="submit"
        >
          Login
        </LoadingButton>
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        Don&apos;t have an account ?
        {' '}
        <a href="https://isvinsights.ai/contact-us" variant="body2" style={{ textDecoration: 'none', color: palette.primary.mainColor }} target="_blank" rel="noreferrer">Contact Us</a>
      </Grid>
    </Grid>
  );
}

export default formikForm(LoginForm);
