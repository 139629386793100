import { CircularProgress, Container, LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPboConnectionDetails } from '../features/PboConnection/PboConnectionAction';
import { usePboConnection } from '../sections/PboConnection/usePboConnection';
import Metatags from '../components/MetaTags/MetaTags';
import PboConnection from '../sections/PboConnection/PboConnection';
import PboIntroductoryScreen from '../sections/PboConnection/PboIntroductory';

export default function SfIntegrationPage() {
  const { pboConnection, pboConnectionDetails } = usePboConnection();
  const { data } = pboConnectionDetails;
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code') ?? null;

  useEffect(() => {
    if (!data || !Object.keys(data).length) {
      if (!code) {
        dispatch(getPboConnectionDetails());
      }
    }
  }, [data, code]);

  if (pboConnection?.loading) {
    return (
      <>
        <Metatags metaTagName="SalesForce Integration | ISV Insights" />
        <Container maxWidth="xl">
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
          >
            <CircularProgress />
          </div>
        </Container>
      </>
    );
  }

  let content = null;
  if (data?.record === true) {
    content = <PboConnection />;
  } else if (data?.record === false) {
    content = <PboIntroductoryScreen />;
  } else {
    content = <LinearProgress />;
  }

  return (
    <>
      <Metatags metaTagName="SalesForce Integration | ISV Insights" />
      <Container maxWidth="xl" sx={(theme) => ({ height: `calc(100% - ${theme.spacing(8)})` })}>
        {content}
      </Container>
    </>
  );
}
