/* eslint-disable react/jsx-no-useless-fragment */
import { Container, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPboConnectionDetails } from '../features/PboConnection/PboConnectionAction';
import Dashboard from '../sections/Dashboard/Dashboard';
import PboIntroductoryScreen from '../sections/PboConnection/PboIntroductory';
import Metatags from '../components/MetaTags/MetaTags';

export default function DashboardAppPage() {
  const dispatch = useDispatch();
  const [showDashboard, setShowDashboard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNewProp, setIsNewProp] = useState();

  useEffect(() => {
    const fetchPboConnectionDetails = async () => {
      const res1 = await dispatch(getPboConnectionDetails());
      setShowDashboard(res1.payload?.data?.record !== false);
      setLoading(false);
      setIsNewProp(res1.payload?.data?.isEnable ? res1.payload?.data?.isNew : false);
    };
    fetchPboConnectionDetails();
  }, [dispatch]);

  return (
    <>
      <Metatags metaTagName="Dashboard | ISV Insights" />
      <Container maxWidth="xl">
        {loading ? (
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
          }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {showDashboard ? <Dashboard isNewProp={isNewProp} /> : <PboIntroductoryScreen />}
          </>
        )}
      </Container>
    </>
  );
}
