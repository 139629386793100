import {
  Container, Box, Button, Grid, useMediaQuery,
} from '@mui/material';
import useSearchTerm from '../sections/FeatureManager/useSearchTerm';
import { SearchBar } from '../components/FormComponents';
import { PackageHeader } from '../components/CustomHeading';
import useStyles from '../hooks/useStyles';
import styles from '../layouts/pages/style';
import { FeatureManager } from '../sections/FeatureManager';
import useFeatureManager from '../sections/FeatureManager/useFeatureManager';
import Metatags from '../components/MetaTags/MetaTags';

export default function FeatureManagerPage({ permissions }) {
  const { searchTerm, handleFilterChange } = useSearchTerm();
  const {
    filteredData,
    handleClickFeatureOpen,
    columns,
    handleClickEdit,
    openDialog,
    handleClose,
    handleDeleteConfirm,
  } = useFeatureManager({ searchTerm, permissions });
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const classes = useStyles(styles)();
  return (
    <>
      <Metatags metaTagName="Feature Manager | ISV Insights" />
      <Container maxWidth="xl">
        <Grid container gap={2}>
          <Grid item xs={12} className={classes.cardStyles}>
            <Box className={classes.headerStyles}>
              <PackageHeader heading="Feature Manager" />
              <Button className="tg-new-kpi" variant="outlined" onClick={handleClickFeatureOpen}>
                Define a New Feature
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.cardStyles}>
            <SearchBar
              label="Feature Name"
              className="tg-kpi-search"
              sxProps={{ className: 'tg-kpi-search', width: isSmallScreen ? '100%' : '40%' }}
              value={searchTerm}
              onChange={(e) => handleFilterChange(e.target.value)}
              onClear={() => handleFilterChange('')}
              data={searchTerm}
            />
          </Grid>
          <Grid item xs={12} className={classes.tableStyles}>
            <FeatureManager
              permissions={permissions}
              data={filteredData}
              columns={columns}
              handleClickEdit={handleClickEdit}
              openDialog={openDialog}
              handleClose={handleClose}
              handleDeleteConfirm={handleDeleteConfirm}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
