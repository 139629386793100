import { Container } from '@mui/material';
import { UserManager } from '../sections/UserManager';
import Metatags from '../components/MetaTags/MetaTags';

function UserManagementPage({ permissions }) {
  return (
    <>
      <Metatags metaTagName="User Manager | ISV Insights" />
      <Container maxWidth="xl">
        <UserManager permissions={permissions} />
      </Container>
    </>
  );
}

export default UserManagementPage;
