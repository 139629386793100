import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureUsage } from '../../../../sections/FeatureUsage';
import { getFeatureUsage } from '../../../../features';
import DashboardCards from '../../../../components/DashboardCard/DashboardCards';
import usePinnedFeatureUsage from './usePinnedFeatureUsage';
import PinnedFeatureCard from './PinnedFeatureCard';

export default function PinnedFeatureUsage({
  orgId, activePackage, accountName, isShare, tenantId, pkgId, accountOrgId,
}) {
  const [viewOption, setViewOption] = useState('month');
  const dispatch = useDispatch();
  const {
    pinnedFeatures, getChartData, refreshFeatureUsage, setRefreshFeatureUsage,
  } = useFeatureUsage({ dashboard: false });

  useEffect(() => {
    if (pkgId || activePackage?.packageId) {
      dispatch(getFeatureUsage({
        packageId: pkgId || activePackage?.packageId, view: viewOption, orgId, isShare, tenantId, accountOrgId,
      }));
    }
  }, [activePackage?.packageId, viewOption, refreshFeatureUsage, isShare, tenantId, pkgId, accountOrgId]);

  const {
    handleSelection, graphType, handleClick, stackHeight,
  } = usePinnedFeatureUsage({ pinnedFeatures, orgId, accountName });

  return (
    <Box>
      <DashboardCards
        showHeader
        showButton={!isShare}
        buttonText="more"
        title="Pinned Features"
        onClick={handleClick}
        graphType={graphType}
        handleSelection={handleSelection}
        error={pinnedFeatures.error}
        viewOption={viewOption}
        setViewOption={setViewOption}
      >
        <PinnedFeatureCard
          loading={pinnedFeatures.loading}
          error={pinnedFeatures.error}
          refresh={setRefreshFeatureUsage}
          stackHeight={stackHeight}
          graphType={graphType}
          handleSelection={handleSelection}
          pinnedFeatures={pinnedFeatures}
          getChartData={getChartData}
          data={pinnedFeatures?.data}
          viewOption={viewOption}
          message="No Data"
        />
      </DashboardCards>
    </Box>
  );
}
