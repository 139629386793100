import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import TourIcon from '@mui/icons-material/Tour';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useLocation, useNavigate } from 'react-router-dom';
import { startTour } from '../../features/TourGuide/tourGuideSlice';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import useAuthorization from '../../hooks/useAuthorization';
import { broadcastMessage } from '../../utils/broadcastEvents';
import { logoutUser, refreshSession } from '../../features/Auth/authAction';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { resetPackageList } from '../../features/Package/packageSlice';

function useAppMenu({ permissions }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const { userInfo } = useAuthorization();

  const handleClick = (index) => {
    if (open === null || open !== index) {
      setOpen(index);
    } else {
      setOpen(null);
    }
  };

  const handleLogout = () => {
    const idToken = localStorage.getItem('idToken');
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    broadcastMessage('LogoutUser');
    const tokens = {
      idToken,
      accessToken,
      refreshToken,
    };
    dispatch(logoutUser(tokens));
  };

  const handleStopImpersonate = () => {
    const refreshToken = localStorage.getItem('refreshToken');
    dispatch(refreshSession({ refreshToken })).then((res) => {
      if (res.payload.status === 'SUCCESS') {
        navigate('/');
        localStorage.removeItem('activePackage');
        dispatch(resetPackageList());
        usePackageSelection();
        dispatch(openSnackbar({ open: true, message: 'Successfully stopped impersonating!', type: 'success' }));
      } else {
        handleLogout();
        dispatch(openSnackbar({ open: true, message: 'Failed to stop impersonating, logging out!', type: 'error' }));
      }
    });
  };

  const handleStartTour = () => {
    if (location.pathname === '/dashboard') {
      dispatch(startTour());
    } else {
      navigate('/dashboard', { state: { startTour: true } });
    }
  };

  const handleExploreDocs = () => {
    window.open('https://doc.isvinsights.ai/', '_blank');
  };

  const isImpersonating = userInfo.access !== 'superAdmin' && userInfo.group === 'superAdmin';
  const isTourGuideDisabled = userInfo.access === 'superAdmin';

  const bottomMenuItems = [
    {
      name: 'Tour Guide',
      link: '',
      Icon: TourIcon,
      permission: isTourGuideDisabled,
      onClick: handleStartTour,
    },
    {
      name: 'Documentation',
      link: '',
      Icon: SummarizeIcon,
      permission: isTourGuideDisabled,
      onClick: handleExploreDocs,
    },
    {
      name: isImpersonating ? 'Stop Impersonating' : 'Profile',
      link: isImpersonating ? '' : '/my-profile',
      Icon: PersonIcon,
      permission: permissions?.manage,
      onClick: isImpersonating ? handleStopImpersonate : undefined,
    },
    {
      name: 'Logout',
      link: '',
      Icon: PowerSettingsNewIcon,
      onClick: handleLogout,
    },
  ];

  return {
    open,
    setOpen,
    handleClick,
    handleLogout,
    bottomMenuItems,
  };
}

export default useAppMenu;
